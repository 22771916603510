html {
  overflow-x: hidden;
  padding: 0px;
  margin: 0px;
}
body {
  font-family: 'Mulish';
  font-size: 15px;
  line-height: 30px;
  letter-spacing: 0.5px;
  word-wrap: break-word;
  font-weight: 400;
  background-color: #fff;
  color: #767676;
  box-sizing: border-box;
}
svg {
  fill: currentcolor;
  width: 15px;
  height: 15px;
}
img.svg {
  width: 15px;
  height: 15px;
}
::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: #6f6f6f;
}
:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #6f6f6f;
  opacity: 1;
}
::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #6f6f6f;
  opacity: 1;
}
:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #6f6f6f;
}
::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #6f6f6f;
}

::placeholder {
  /* Most modern browsers support this now. */
  color: #6f6f6f;
}

.container {
  max-width: 960px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  height: auto;
  padding: 0px 40px;
  position: relative;
  clear: both;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 500;
  line-height: 1.2;
  font-family: 'Montserrat';
  color: #000;
}

h1 {
  font-size: 45px;
}
h2 {
  font-size: 36px;
}
h3 {
  font-size: 30px;
}
h4 {
  font-size: 24px;
}
h5 {
  font-size: 20px;
}
h6 {
  font-size: 16px;
}

.tokyo_tm_all_wrap {
  width: 100%;
  height: auto;
  clear: both;
  float: left;
  position: relative;
}
.tokyo_tm_all_wrap,
.tokyo_tm_all_wrap * {
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box; /* Opera/IE 8+ */
}

#preloader {
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100%;
  z-index: 99999;
  display: flex;
}
#preloader:before,
#preloader:after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 50%;
  height: 100%;
  z-index: -1;
  background-color: #000;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
#preloader:after {
  left: auto;
  right: 0;
}
#preloader .loader_line {
  margin: auto;
  width: 1px;
  height: 250px;
  position: relative;
  overflow: hidden;
  -webkit-transition: all 0.8s ease 0s;
  -o-transition: all 0.8s ease 0s;
  transition: all 0.8s ease 0s;
}
.loader_line:before {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  width: 1px;
  height: 0%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  background-color: #fff;
  -webkit-animation: lineheight 1000ms ease-in-out 0s forwards;
  -o-animation: lineheight 1000ms ease-in-out 0s forwards;
  animation: lineheight 1000ms ease-in-out 0s forwards;
}
.loader_line:after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 1px;
  height: 100%;
  background-color: #999999;
  -webkit-transform: translateY(-100%);
  -ms-transform: translateY(-100%);
  -o-transform: translateY(-100%);
  transform: translateY(-100%);
  -webkit-animation: lineround 1200ms linear 0s infinite;
  -o-animation: lineround 1200ms linear 0s infinite;
  animation: lineround 1200ms linear 0s infinite;
  animation-delay: 2000ms;
}

@keyframes lineheight {
  0% {
    height: 0%;
  }
  100% {
    height: 100%;
  }
}

@keyframes lineround {
  0% {
    -webkit-transform: translateY(-100%);
    -ms-transform: translateY(-100%);
    -o-transform: translateY(-100%);
    transform: translateY(-100%);
  }
  100% {
    -webkit-transform: translateY(200%);
    -ms-transform: translateY(200%);
    -o-transform: translateY(200%);
    transform: translateY(200%);
  }
}

/*hiding all*/
.preloaded .loader_line:after {
  opacity: 0;
}
.preloaded .loader_line {
  opacity: 0;
  height: 100% !important;
}
.preloaded:before,
.preloaded:after {
  -webkit-animation: preloadedzero 300ms ease-in-out 500ms forwards;
  -o-animation: preloadedzero 300ms ease-in-out 500ms forwards;
  animation: preloadedzero 300ms ease-in-out 500ms forwards;
}
@keyframes preloadedzero {
  0% {
    width: 50%;
  }
  100% {
    width: 0%;
  }
}

/*---------------------------------------------------*/
/*	02) TOKYO LEFTPART
/*---------------------------------------------------*/

.tokyo_tm_all_wrap .leftpart {
  width: 450px;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  z-index: 10;
  padding: 0px 100px;
  background-color: #fff;
}
.tokyo_tm_all_wrap .leftpart_inner {
  width: 100%;
  height: auto;
}
.tokyo_tm_all_wrap .leftpart img {
  max-width: 150px;
}
.tokyo_tm_all_wrap .leftpart .menu {
  padding: 40px 0px 50px 0px;
  width: 100%;
  float: left;
}
.tokyo_tm_all_wrap .leftpart .menu ul {
  margin: 0px;
  list-style-type: none;
}
.tokyo_tm_all_wrap .leftpart .menu ul li {
  margin: 0px;
  width: 100%;
  float: left;
  text-decoration: none;
  color: #767676;
  display: inline-block;
  font-weight: 500;
  font-family: 'Montserrat';
  transition: all 0.3s ease;
  cursor: pointer;
  .svg {
    display: none;
  }
}
.tokyo_tm_all_wrap .leftpart .menu > ul li:hover {
  color: #000;
  letter-spacing: 2px;
}
.tokyo_tm_all_wrap .leftpart .menu ul li.react-tabs__tab--selected {
  color: #000;
  letter-spacing: 2px;
}
.tokyo_tm_all_wrap .leftpart .copyright {
  width: 100%;
  float: left;
}
.tokyo_tm_all_wrap .leftpart .copyright p {
  font-size: 15px;
  color: #999;
  font-family: 'Montserrat';
  line-height: 25px;
  font-style: italic;
}
.tokyo_tm_all_wrap .leftpart .copyright a {
  text-decoration: none;
  color: #787878;
  font-weight: 500;
  padding-left: 5px;

  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.tokyo_tm_all_wrap .leftpart .copyright a:hover {
  color: #000;
  letter-spacing: 2px;
}

/*---------------------------------------------------*/
/*	03) TOKYO RIGHTPART
/*---------------------------------------------------*/

.tokyo_tm_all_wrap .rightpart {
  width: 100%;
  min-height: 100vh;
  float: left;
  position: relative;
  background-color: #f8f8f8;
  padding-left: 450px;
}
.tokyo_tm_all_wrap .rightpart_in {
  position: relative;
  width: 100%;
  float: left;
  clear: both;
  border-left: 1px solid #ebebeb;
  min-height: 100vh;
}
.tokyo_tm_section {
  position: fixed;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  padding-left: 450px;
  overflow-x: hidden;
  overflow-y: auto;
}
// page animation
.tokyo_tm_home,
.tokyo_tm_about,
.tokyo_tm_portfolio,
.tokyo_tm_news,
.tokyo_tm_contact {
  -webkit-animation: 800ms ease-in-out 0s normal none 1 running customOne;
  animation: 800ms ease-in-out 0s normal none 1 running customOne;
}

@keyframes customOne {
  from {
    opacity: 0;
    transform: translateX(-50px);
  }
  to {
    opacity: 1;
    transform: none;
  }
}

.customOne {
  animation-name: customOne;
}

// preview page animation
.tokyo_tm_intro .left,
.tokyo_tm_intro .right {
  -webkit-animation: 800ms ease-in-out 0s normal none 1 running slideTop;
  animation: 800ms ease-in-out 0s normal none 1 running slideTop;
}

@keyframes slideTop {
  from {
    opacity: 0;
    transform: translateY(-50px);
  }
  to {
    opacity: 1;
    transform: none;
  }
}

.slideTop {
  animation-name: slideTop;
}

.tokyo_tm_section .list_wrapper .react-tabs__tab-panel--selected {
  -webkit-animation: 800ms ease-in-out 0s normal none 1 running slideTop;
  animation: 800ms ease-in-out 0s normal none 1 running slideTop;
}

/*---------------------------------------------------*/
/*	04) TOKYO HOME
/*---------------------------------------------------*/

.tokyo_tm_home {
  width: 100%;
  min-height: 100vh;
  clear: both;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.tokyo_tm_home .home_content {
  display: flex;
  align-items: center;
}
.tokyo_tm_home .avatar {
  min-width: 300px;
  min-height: 300px;
  position: relative;
  border-radius: 100%;
}
.tokyo_tm_home .avatar .image {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  animation: morph 8s ease-in-out infinite 1s;
  background-blend-mode: multiply;
  box-shadow: inset 0px 0px 0px 9px rgba(255, 255, 255, 0.3);
  border-radius: 60% 40% 30% 70% / 60% 30% 70% 40%;
  overflow: hidden;
}

@keyframes morph {
  0% {
    border-radius: 60% 40% 30% 70% / 60% 30% 70% 40%;
  }
  50% {
    border-radius: 30% 60% 70% 40% / 50% 60% 30% 60%;
  }
  100% {
    border-radius: 60% 40% 30% 70% / 60% 30% 70% 40%;
  }
}

.tokyo_tm_home .details {
  margin-left: 80px;
}
.tokyo_tm_home .details .name {
  font-size: 55px;
  font-weight: 800;
  text-transform: uppercase;
  margin-bottom: 19px;
}
.tokyo_tm_home .details .job {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 500;
  max-width: 450px;
  margin-bottom: 30px;
}
.tokyo_tm_home .social {
  width: 100%;
  float: left;
}
.social {
  margin: 0px;
  list-style-type: none;
}
.social li {
  margin: 0px 15px 0px 0px;
  display: inline-block;
}
.social li:last-child {
  margin-right: 0px;
}
.social li a {
  text-decoration: none;
  color: #000;

  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.social li a:hover {
  filter: opacity(0.7);
}
.social li .svg {
  width: 17px;
  height: 17px;
}

/*---------------------------------------------------*/
/*	05) TOKYO ABOUT
/*---------------------------------------------------*/

.tokyo_tm_about {
  width: 100%;
  height: auto;
  clear: both;
  float: left;
  padding: 100px 0px 100px 0px;
}
.tokyo_tm_about .about_image {
  width: 100%;
  height: auto;
  clear: both;
  float: left;
  margin-bottom: 48px;
}
.tokyo_tm_about .about_image img {
  min-width: 100%;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.12);
}
.tokyo_tm_about .description {
  width: 100%;
  height: auto;
  clear: both;
}
.tokyo_tm_about .description_inner {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.tokyo_tm_about .description .left {
  width: 55%;
  padding-right: 50px;
}
.tokyo_tm_about .description .left p {
  margin-bottom: 35px;
}
.tokyo_tm_about .description .name {
  font-weight: 700;
  font-size: 22px;
  margin-bottom: 26px;
}
.tokyo_tm_about .description .right {
  width: 45%;
  padding-left: 50px;
}
.tokyo_tm_about .description .right > ul {
  margin: 0px;
  list-style-type: none;
}
.tokyo_tm_about .description .right > ul > li {
  margin: 0px;
  width: 100%;
  float: left;
}
.tokyo_tm_about .description .right > ul > li span {
  min-width: 90px;
  display: inline-block;
  font-weight: 700;
  color: #000;
}
.tokyo_tm_about .description .right > ul > li a {
  text-decoration: none;
  color: #787878;

  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.tokyo_tm_about .description .right > ul > li a:hover {
  color: #000;
  letter-spacing: 2px;
}
.tokyo_tm_button {
  width: 100%;
  height: auto;
  clear: both;
  float: left;
}
.tokyo_tm_button a,
.ib-button {
  text-decoration: none;
  color: #fff;
  display: inline-block;
  background-color: #000;
  padding: 9px 40px 14px 40px;

  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.ib-button {
  font-size: 15px;
  line-height: 15px;
  padding: 19px 40px;
  border: none;
  cursor: pointer;
  &:focus {
    outline: none;
  }
}
.tokyo_tm_button a:hover,
.ib-button:hover {
  letter-spacing: 2px;
}

.tokyo_tm_modalbox_about .close .svg {
  width: 50px;
  height: 50px;
}

.tokyo_tm_modalbox_about .description_wrap {
  position: relative;
  padding: 110px 70px 70px;
  overflow: auto;
}
.tokyo_tm_modalbox_about .my_box {
  width: 100%;
  height: auto;
  clear: both;
  display: flex;
  margin-bottom: 92px;
  @media screen and (max-width: 767px) {
    margin-bottom: 60px;
  }
}
.tokyo_tm_modalbox_about .my_box .left {
  width: 50%;
  padding-right: 50px;
}
.tokyo_tm_modalbox_about .about_title h3 {
  font-weight: 700;
  color: #000;
  font-size: 20px;
}

.tokyo_progress {
  width: 100%;
  height: auto;
  clear: both;
  float: left;
  padding-top: 33px;
}
.progress_inner {
  width: 100%;
  margin-bottom: 17px;
}
.progress_inner:last-child {
  margin-bottom: 0px;
}
.progress_inner > span {
  margin: 0px 0px 5px 0px;
  width: 100%;
  display: block;
  text-align: left;
  color: #000;
  font-style: italic;
}
.progress_inner span.number {
  float: right;
}
.progress_inner .background {
  background: rgba(0, 0, 0, 0.09);
  width: 100%;
  min-width: 100%;
  position: relative;
  height: 3px;
}
.progress_inner .background .bar_in {
  height: 100%;
  background: #000;
  width: 0px;
  overflow: hidden;
}
.progress_inner .background .bar {
  width: 100%;
  height: 100%;
}
.progress_inner .background .bar.open {
  -webkit-animation: wow 2s cubic-bezier(0.165, 0.84, 0.44, 1); /* Safari 4+ */
  -moz-animation: wow 2s cubic-bezier(0.165, 0.84, 0.44, 1); /* Fx 5+ */
  animation: wow 2s cubic-bezier(0.165, 0.84, 0.44, 1); /* IE 10+ */
  width: 100%;
}

@-webkit-keyframes wow {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}
@-moz-keyframes wow {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}
@keyframes wow {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}

.tokyo_tm_modalbox_about .my_box .right {
  width: 50%;
  padding-left: 50px;
}
.tokyo_tm_modalbox_about .counter {
  width: 100%;
  float: left;
  clear: both;
  @media screen and (max-width: 767px) {
    margin-bottom: 30px;
  }
}
.tokyo_tm_modalbox_about .counter ul {
  margin: 0px 0px 42px -30px;
  list-style-type: none;
  padding-top: 45px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.tokyo_tm_modalbox_about .counter ul li {
  margin: 0px 0px 50px 0px;
  width: 33.3333%;
  float: left;
  padding-left: 30px;
  @media screen and (max-width: 767px) {
    margin-bottom: 30px;
  }
}
.tokyo_tm_modalbox_about .list_inner {
  width: 100%;
  height: 100%;
  clear: both;
  float: left;
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.1);
  text-align: center;
  padding: 40px 20px;
}
.tokyo_tm_modalbox_about .counter ul li h3 {
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 3px;
}
.tokyo_tm_modalbox_about .counter ul li span {
  font-style: italic;
}
.tokyo_tm_modalbox_about .partners {
  width: 100%;
  height: auto;
  clear: both;
  float: left;
}
.tokyo_tm_modalbox_about .partners {
  .slick-list {
    margin-top: 20px;
    .slick-slide {
      li {
        img {
          background: #efefef;
        }
      }
    }
  }
}
.tokyo_tm_modalbox_about .partners ul {
  margin: 0px;
  list-style-type: none;
  padding-top: 15px;
}
.tokyo_tm_modalbox_about .partners ul {
  .slick-initialized .slick-slide {
    padding: 5px;
  }
}
.tokyo_tm_modalbox_about .partners ul li {
  margin: 0px;
  opacity: 0.5;
  transition: all 0.3s ease;
}
.tokyo_tm_modalbox_about .partners ul li:hover {
  opacity: 1;
}

/*---------------------------------------------------*/
/*	06) TOKYO PORTFOLIO
/*---------------------------------------------------*/

.tokyo_tm_title {
  width: 100%;
  height: auto;
  clear: both;
  float: left;
  margin-bottom: 62px;
}
.tokyo_tm_title h3 {
  font-weight: 800;
  font-family: 'Montserrat';
}
.tokyo_tm_title span {
  display: inline-block;
  background-color: rgba(0, 0, 0, 0.04);
  text-transform: uppercase;
  padding: 4px 10px;
  font-weight: 600;
  font-size: 12px;
  color: #333;
  font-family: 'Montserrat';
  letter-spacing: 0px;
  margin-bottom: 15px;
}
.tokyo_tm_title .title_flex {
  width: 100%;
  height: auto;
  clear: both;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.tokyo_tm_portfolio .portfolio_filter .react-tabs__tab-list {
  margin: 0px;
  list-style-type: none;
}
.tokyo_tm_portfolio .portfolio_filter .react-tabs__tab-list li {
  margin: 0px 25px 0px 0px;
  text-decoration: none;
  color: #767676;
  display: inline-block;
  font-weight: 500;
  font-family: 'Montserrat';
  transition: all 0.3s ease;
  cursor: pointer;
  @media screen and (max-width: 767px) {
    margin: 0px 15px 0px 0px;
  }
}
.tokyo_tm_portfolio .portfolio_filter .react-tabs__tab-list li:last-child {
  margin-right: 0px;
}

.tokyo_tm_portfolio .portfolio_filter .react-tabs__tab-list li:hover,
.tokyo_tm_portfolio
  .portfolio_filter
  .react-tabs__tab-list
  li.react-tabs__tab--selected {
  color: #000;
  letter-spacing: 2px;
}
.tokyo_tm_portfolio .list_wrapper {
  width: 100%;
  height: auto;
  clear: both;
  float: left;
}
.tokyo_tm_portfolio {
  width: 100%;
  height: auto;
  clear: both;
  float: left;
  padding: 100px 0px 50px 0px;
  position: relative;
}
.tokyo_tm_portfolio .portfolio_list {
  margin: 0px 0px 0px -50px;
  list-style-type: none;
}
.tokyo_tm_portfolio .portfolio_list li {
  margin: 0px 0px 50px 0px;
  float: left;
  width: 50%;
  padding-left: 50px;
  @media (max-width: 576px) {
    margin: 0px 0px 30px 0px;
  }
}
.tokyo_tm_portfolio .portfolio_list li .inner {
  width: 100%;
  height: 100%;
  clear: both;
  float: left;
  overflow: hidden;
  position: relative;
}
.tokyo_tm_portfolio .portfolio_list li .inner a {
  position: relative;
  overflow: hidden;
  &:hover {
    img {
      transform: scale(1.1);
    }
  }
}
.tokyo_tm_portfolio .portfolio_list li .inner img {
  width: 100%;
  height: 415px;
  object-fit: cover;
  transition: 0.3s;
}
ul.react-tabs__tab-list {
  position: absolute;
  right: 0;
  top: 158px;
  z-index: 2;
  @media screen and (max-width: 1199px) {
    top: 225px;
  }
  @media screen and (max-width: 767px) {
    position: static;
    padding-bottom: 25px;
  }
}
// .tokyo_tm_portfolio .portfolio_list li .inner img{
// 	opacity: 0;
// 	min-width: 100%;
// }
.tokyo_tm_portfolio .portfolio_list li .inner .main_image {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.tokyo_tm_portfolio .portfolio_list li .inner:hover .main_image {
  transform: scale(1.1) translateZ(0);
}
.tokyo_tm_portfolio_titles {
  white-space: nowrap;
  background: #ffffff;
  font-size: 18px;
  padding: 5px 15px;
  font-family: 'Montserrat';
  font-weight: 600;
  color: #000;
  position: fixed;
  z-index: 6;
  opacity: 0;
  visibility: hidden;
}
.tokyo_tm_portfolio_titles.visible {
  opacity: 1;
  visibility: visible;
}
.tokyo_tm_portfolio_titles .work__cat {
  position: absolute;
  background: #ffffff;
  top: 100%;
  left: 0;
  margin-top: -10px;
  font-family: 'Mulish';
  font-weight: 500;
  padding: 5px 15px;
  color: #767676;
  font-size: 15px;
  font-style: italic;
}
.entry {
  position: relative;
}

/*---------------------------------------------------*/
/*	07) TOKYO NEWS 
/*---------------------------------------------------*/

.tokyo_tm_news {
  width: 100%;
  height: auto;
  clear: both;
  float: left;
  padding: 100px 0px 45px 0px;
}
.tokyo_tm_news ul {
  margin: 0px 0px 0px -50px;
  list-style-type: none;
}
.tokyo_tm_news ul li {
  margin: 0px 0px 45px 0px;
  float: left;
  width: 50%;
  padding-left: 50px;
  @media screen and (max-width: 576px) {
    margin: 0px 0px 40px 0px;
  }
}
.tokyo_tm_news ul li .list_inner {
  width: 100%;
  height: auto;
  clear: both;
  float: left;
  position: relative;
  background: #fff;
  box-shadow: 0 0 20px #dfdfdf;
}
.tokyo_tm_news ul li .image {
  position: relative;
  overflow: hidden;
  cursor: pointer;
}
.tokyo_tm_news ul li .image img {
  min-width: 100%;
  opacity: 0;
}
.tokyo_tm_news ul li .image .main {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  transition: all 0.3s ease-in-out;
}
.tokyo_tm_news ul li .list_inner:hover .main {
  transform: scale(1.1) translateZ(0);
}
.tokyo_tm_news ul li .details {
  width: 100%;
  float: left;
  padding: 30px 40px 25px;
  padding-top: 32px;
  @media screen and (max-width: 767px) {
    padding: 30px 25px 25px;
  }
}
.tokyo_tm_news ul li .details .title {
  position: relative;
  margin-bottom: 10px;
  text-decoration: none;
  color: #000;
  font-size: 18px;
  font-weight: 600;
  line-height: 1.4;
  display: inline-block;
  cursor: pointer;
}
.tokyo_tm_news ul li .extra {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  position: relative;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 5px;
}
.tokyo_tm_read_more {
  width: 100%;
  height: auto;
  clear: both;
  float: left;
  position: relative;
  overflow: hidden;
}
.tokyo_tm_read_more a {
  text-decoration: none;
  display: inline-block;
  overflow: hidden;
  color: #000;
  padding-right: 32px;
  position: relative;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 12px;
  font-family: montserrat;
  cursor: pointer;
}
.tokyo_tm_read_more a:before {
  position: absolute;
  content: '';
  background-color: #000;
  margin: auto;
  width: 100%;
  height: 1px;
  top: 0;
  left: 0;
  bottom: 0;
  transform: scaleX(0.2);
  transform-origin: left center;
  animation: read-more-anim;
  animation-fill-mode: forwards;
  animation-duration: 0.4s;
  animation-timing-function: cubic-bezier(0.6, 0.01, 0, 1);
}
.tokyo_tm_read_more a span {
  display: inline-block;
  position: relative;
  background-color: #fff;
  z-index: 1;
  transition: 0.6s cubic-bezier(0.6, 0.01, 0, 1);
  transform: translateX(-110%);
  @media screen and (max-width: 767px) {
    transform: translateX(0%);
  }
}
.tokyo_tm_news ul li .details .title:hover {
  color: #000;
}
.tokyo_tm_news ul li .list_inner .tokyo_tm_read_more a:before {
  @media screen and (max-width: 767px) {
    animation: read-more-anim-2;
    animation-fill-mode: forwards;
    animation-duration: 0.4s;
    animation-timing-function: cubic-bezier(0.6, 0.01, 0, 1);
  }
}
.tokyo_tm_news ul li .list_inner:hover .tokyo_tm_read_more a:before {
  animation: read-more-anim-2;
  animation-fill-mode: forwards;
  animation-duration: 0.4s;
  animation-timing-function: cubic-bezier(0.6, 0.01, 0, 1);
}

@keyframes read-more-anim-2 {
  0% {
    transform-origin: left center;
    transform: scaleX(0.2);
  }
  70% {
    transform-origin: left center;
    transform: scaleX(1);
  }
  71% {
    transform-origin: right center;
  }
  100% {
    transform-origin: right center;
    transform: scaleX(0.2);
  }
}
.tokyo_tm_news ul li .details .date {
  text-transform: capitalize;
  font-family: 'Montserrat';
  font-size: 13px;
  color: #767676;
  font-style: italic;
}
.tokyo_tm_news ul li .list_inner:hover .tokyo_tm_read_more a span {
  transform: translateX(0%);
}
.tokyo_tm_news ul li .details .date a {
  text-decoration: none;
  color: #767676;
  transition: all 0.3s ease;
}
.tokyo_tm_news ul li .details .date a:hover {
  color: #000;
}
.tokyo_tm_news ul li .details .date span {
  position: relative;
  margin-left: 11px;
}
.tokyo_tm_news ul li .details .date span:before {
  position: absolute;
  content: '';
  margin-top: 1px;
  top: 50%;
  transform: translateY(-50%) rotate(15deg);
  right: 100%;
  background-color: #939393;
  width: 1px;
  height: 9px;
  margin-right: 5px;
}
.tokyo_tm_full_link {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  z-index: 4;
}
.tokyo_tm_modalbox_news .box_inner,
.tokyo_tm_modalbox_about .box-inner {
  height: 75vh;
  width: 968px;
  overflow-y: auto;
  @media only screen and (min-width: 992px) and (max-width: 1024px) {
    width: 850px;
  }
  @media only screen and (min-width: 767px) and (max-width: 992px) {
    width: 730px;
  }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    width: 530px;
    height: 500px;
  }
  @media only screen and (min-width: 350px) and (max-width: 575px) {
    width: 350px;
    height: 500px;
  }
  @media only screen and (min-width: 0px) and (max-width: 349px) {
    width: 300px;
    height: 500px;
  }
}
.tokyo_tm_modalbox_news.opened {
  opacity: 1;
  visibility: visible;
}
.tokyo_tm_modalbox_news .container {
  height: 100vh;
}

.tokyo_tm_modalbox_news.opened .box_inner {
  opacity: 1;
  visibility: visible;
  margin-top: 0px;
}
.tokyo_tm_modalbox_news .close {
  position: fixed;
  left: 100%;
  top: 0px;
  margin-left: 40px;
  z-index: 111111;
}
.tokyo_tm_modalbox_news .close a {
  text-decoration: none;
  color: #fff;
}
.tokyo_tm_modalbox_news .close .svg {
  width: 50px;
  height: 50px;
}
.tokyo_tm_modalbox_news .description_wrap {
  position: relative;
  width: 100%;
  height: 100%;
  float: left;
  overflow-y: auto;
  padding: 70px;
}
.tokyo_tm_modalbox_news .details {
  width: 100%;
  float: left;
  margin-bottom: 30px;
}
.tokyo_tm_modalbox_news .description_wrap .image {
  position: relative;
  max-height: 450px;
  z-index: -1;
  margin-bottom: 40px;
}
.tokyo_tm_modalbox_news .description_wrap .image img {
  min-width: 100%;
}
.tokyo_tm_modalbox_news .description_wrap .image .main {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.tokyo_tm_modalbox_news .details .extra {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  position: relative;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 8px;
}
.tokyo_tm_modalbox_news .details .title {
  font-weight: 600;
  font-size: 23px;
  margin-bottom: 9px;
  @media screen and (max-width: 991px) {
    line-height: 1.5;
  }
}
.tokyo_tm_modalbox_news .date {
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-size: 12px;
  color: #767676;
  font-style: italic;
}
.tokyo_tm_modalbox_news .date a {
  text-decoration: none;
  color: #767676;

  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.tokyo_tm_modalbox_news .date a:hover {
  color: #e54b4b;
}
.tokyo_tm_modalbox_news .date span {
  position: relative;
  margin-left: 11px;
}
.tokyo_tm_modalbox_news .date span:before {
  position: absolute;
  content: '';
  margin-top: 0px;
  top: 50%;
  transform: translateY(-50%) rotate(15deg);
  right: 100%;
  background-color: #939393;
  width: 1px;
  height: 9px;
  margin-right: 7px;
}
.tokyo_tm_news .main_content {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  z-index: -11;
  display: none;
}
body.modal {
  overflow-y: hidden;
}
.tokyo_tm_modalbox_news .main_content {
  width: 100%;
  float: left;
}
.tokyo_tm_modalbox_news .main_content .descriptions {
  width: 100%;
  float: left;
  margin-bottom: 15px;
}
.tokyo_tm_modalbox_news .main_content .descriptions .bigger {
  color: #888;
  font-size: 20px;
  margin-bottom: 33px;
}
.tokyo_tm_modalbox_news .main_content .descriptions p {
  margin-bottom: 22px;
}
.tokyo_tm_modalbox_news .main_content .quotebox {
  width: 100%;
  height: auto;
  clear: both;
  float: left;
  position: relative;
  padding-left: 70px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.tokyo_tm_modalbox_news .main_content .quotebox p {
  font-size: 20px;
  font-style: italic;
  margin-bottom: 23px;
}
.tokyo_tm_modalbox_news .main_content .icon {
  position: absolute;
  left: 0px;
  top: 10px;
}
.tokyo_tm_modalbox_news .main_content .icon .svg {
  width: 40px;
  height: 40px;
  color: #000;
}
.tokyo_tm_modalbox_news .news_share {
  width: 100%;
  height: auto;
  clear: both;
  display: flex;
  align-items: center;
}
.tokyo_tm_modalbox_news .news_share span {
  font-family: 'Montserrat';
  font-weight: 600;
  font-style: italic;
  padding-right: 20px;
  color: #000;
}
.tokyo_tm_modalbox_news .news_share ul {
  margin: 0px;
  list-style-type: none;
  position: relative;
  top: -2px;
}
.tokyo_tm_modalbox_news .news_share ul li {
  margin: 0px 12px 0px 0px;
  display: inline-block;
}
.tokyo_tm_modalbox_news .news_share ul li .svg {
  width: 15px;
  height: 15px;
}

/*---------------------------------------------------*/
/*	08) TOKYO CONTACT
/*---------------------------------------------------*/

.tokyo_tm_contact {
  width: 100%;
  height: auto;
  clear: both;
  float: left;
  padding: 100px 0px 100px 0px;
}
.tokyo_tm_contact .map_wrap {
  width: 100%;
  height: auto;
  clear: both;
  float: left;
  margin-bottom: 50px;
}
.tokyo_tm_contact .map_wrap .map > div {
  height: 400px !important;
  width: 100% !important;
}
.tokyo_tm_contact .map_wrap .map iframe {
  width: 100%;
  height: 100%;
}
.tokyo_tm_contact .fields {
  width: 100%;
  height: auto;
  clear: both;
  float: left;
}
.tokyo_tm_contact .fields .first {
  width: 100%;
  float: left;
}
.tokyo_tm_contact .fields ul {
  margin: 0px;
  list-style-type: none;
}
.tokyo_tm_contact .fields ul li {
  width: 100%;
  margin: 0px 0px 30px 0px;
  float: left;
}
.tokyo_tm_contact .fields ul li input {
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.2);
  background-color: transparent;
  font-style: italic;
}
.tokyo_tm_contact .fields ul li input:focus {
  outline: none;
  border: 1px solid rgba(0, 0, 0, 0.5);
}
.tokyo_tm_contact .fields textarea {
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.2);
  height: 120px;
  resize: none;
  background-color: transparent;
}
.tokyo_tm_contact .fields textarea:focus {
  outline: none;
  border: 1px solid rgba(0, 0, 0, 0.5);
}
.tokyo_tm_contact .empty_notice {
  color: #f52225;
  margin-bottom: 7px;
  display: none;
  text-align: left;
  font-weight: 500;
}
.tokyo_tm_contact ul li span {
  color: #f52225;
  text-align: left;
  font-weight: 500;
}
.tokyo_tm_contact .returnmessage {
  color: #3a00ff;
  margin-bottom: 7px;
  text-align: left;
  font-weight: 500;
}

/*---------------------------------------------------*/
/*	9) TOKYO MAGIC CURSOR
/*---------------------------------------------------*/

.tokyo_tm_all_wrap[data-magic-cursor='hide'] .mouse-cursor {
  display: none;
}
.mouse-cursor {
  position: fixed;
  left: 0;
  top: 0;
  pointer-events: none;
  border-radius: 50%;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  visibility: hidden;
}
.cursor-inner {
  margin-left: -3px;
  margin-top: -3px;
  width: 6px;
  height: 6px;
  z-index: 50;
  background-color: #000;
  -webkit-transition: width 0.3s ease-in-out, height 0.3s ease-in-out,
    margin 0.3s ease-in-out, opacity 0.3s ease-in-out;
  transition: width 0.3s ease-in-out, height 0.3s ease-in-out,
    margin 0.3s ease-in-out, opacity 0.3s ease-in-out;
}
.cursor-inner.cursor-hover {
  margin-left: -40px;
  margin-top: -40px;
  width: 80px;
  height: 80px;
  background-color: #000;
  opacity: 0.3;
}
.cursor-outer {
  margin-left: -15px;
  margin-top: -15px;
  width: 30px;
  height: 30px;
  border: 2px solid #000;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  z-index: 50;
  opacity: 0.5;
  -webkit-transition: all 0.08s ease-out;
  transition: all 0.08s ease-out;
}
.cursor-outer.cursor-hover {
  opacity: 0;
}

/*---------------------------------------------------*/
/*	10) TOKYO MEDIA QUERIES (FOR SMALL DEVIVES)
/*---------------------------------------------------*/

@media (max-width: 1600px) {
  .tokyo_tm_all_wrap .leftpart {
    width: 400px;
  }
  .tokyo_tm_section {
    padding-left: 400px;
  }
  .tokyo_tm_all_wrap .rightpart {
    padding-left: 400px;
  }
}
@media (max-width: 1200px) {
  .tokyo_tm_mobile_menu {
    display: block;
  }
  .tokyo_tm_section {
    padding-left: 0px;
  }
  .tokyo_tm_all_wrap .leftpart {
    position: fixed;
    width: 100%;
    height: auto;
    padding: 0;
    top: 0px;
    left: 0px;
    right: 0px;
    z-index: 10;
    background-color: #fff;
    box-shadow: 0 0 30px rgba(62, 68, 125, 0.08);
  }
  .tokyo_tm_all_wrap .leftpart .leftpart_inner {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 40px;
    @media screen and (max-width: 420px) {
      padding: 20px 15px;
    }
  }
  .tokyo_tm_all_wrap .leftpart .leftpart_inner .navbar-brand img {
    max-width: 100px;
  }
  .tokyo_tm_all_wrap .leftpart .leftpart_inner .copyright {
    display: none;
  }
  .tokyo_tm_all_wrap .leftpart .leftpart_inner .menu {
    padding: 0;
    width: auto;
    float: none;
  }
  .tokyo_tm_all_wrap .leftpart .leftpart_inner .menu ul {
    display: flex;
    align-items: center;
  }
  .tokyo_tm_all_wrap .leftpart .leftpart_inner .menu ul li {
    .svg {
      display: block;
    }
    .menu_content {
      display: none;
    }
    &:not(:last-child) {
      margin-right: 15px;
    }
    &.react-tabs__tab--selected {
      filter: invert(0.4);
    }
  }
  .tokyo_tm_all_wrap .rightpart {
    padding-left: 0px;
  }
  .tokyo_tm_all_wrap .rightpart_in {
    border-left: none;
  }

  .tokyo_tm_about,
  .tokyo_tm_home,
  .tokyo_tm_portfolio,
  .tokyo_tm_contact,
  .tokyo_tm_news {
    padding-top: 150px;
  }
  .tokyo_tm_home {
    padding-bottom: 120px;
  }
  .tokyo_tm_modalbox_news .close a {
    color: #000;
  }
  .tokyo_tm_modalbox_news .close {
    left: auto;
    right: 5px;
    top: 25px;
  }
  .tokyo_tm_modalbox_news .close .svg {
    width: 25px;
    height: 25px;
  }
  .tokyo_tm_modalbox_about .close a {
    color: #000;
  }
  .tokyo_tm_modalbox_about .close {
    left: auto;
    right: 5px;
    top: 25px;
  }
  .tokyo_tm_modalbox_about .close .svg {
    width: 25px;
    height: 25px;
  }

  .not-found-wrapper .tokyo_tm_error {
    height: calc(100vh + 70px);
  }
}
@media (max-width: 1040px) {
  .mouse-cursor {
    display: none;
  }
  .tokyo_tm_mobile_menu .menu_inner {
    padding: 20px;
  }
  .tokyo_tm_home .home_content {
    flex-direction: column;
    text-align: center;
  }
  .tokyo_tm_home .avatar {
    margin-bottom: 30px;
  }
  .tokyo_tm_home .details {
    margin-left: 0px;
  }
  .tokyo_tm_about .description_inner {
    flex-direction: column;
  }
  .tokyo_tm_about .description .left {
    width: 100%;
    padding: 0px;
    margin-bottom: 50px;
  }
  .tokyo_tm_about .description .right {
    width: 100%;
    padding: 0px;
  }
  .tokyo_tm_title .title_flex {
    flex-direction: column;
    align-items: flex-start;
  }
  .tokyo_tm_title .portfolio_filter {
    padding-top: 48px;
  }
  .tokyo_tm_modalbox_news .description_wrap {
    padding: 40px 25px;
  }
  .tokyo_tm_modalbox_about .description_wrap {
    padding: 90px 25px 60px;
  }
  .tokyo_tm_modalbox_about .my_box {
    flex-direction: column;
  }
  .tokyo_tm_modalbox_about .my_box .left {
    width: 100%;
    padding-right: 0px;
    margin-bottom: 50px;
  }
  .tokyo_tm_modalbox_about .my_box .right {
    width: 100%;
    padding-left: 0px;
  }
  .container {
    padding: 0px 20px;
  }
}
@media (max-width: 767px) {
  .tokyo_tm_home .details .name {
    font-size: 35px;
  }
  .tokyo_tm_home .avatar {
    min-width: 200px;
    min-height: 200px;
  }
  .tokyo_tm_portfolio .portfolio_list {
    margin: 0px;
  }
  .tokyo_tm_portfolio .portfolio_list li {
    width: 100%;
    padding-left: 0px;
  }
  .tokyo_tm_news ul {
    margin: 0px;
  }
  .tokyo_tm_news ul li {
    width: 100%;
    padding-left: 0px;
  }
  .tokyo_tm_modalbox_about .description_wrap {
    padding: 60px 25px 30px;
  }
  .tokyo_tm_modalbox_news .main_content .icon {
    position: relative;
    margin-bottom: 30px;
  }
  .tokyo_tm_modalbox_news .main_content .quotebox {
    padding-left: 0px;
  }
  .tokyo_tm_modalbox_about .counter ul {
    margin: 0px;
  }
  .tokyo_tm_modalbox_about .counter ul li {
    width: 100%;
    padding-left: 0px;
  }

  .not-found-wrapper .tokyo_tm_error {
  }
}

/*---------------------------------------------------*/
/*	11) TOKYO 404 
/*---------------------------------------------------*/

.tokyo_tm_section .container {
  height: 100%;
}
.tokyo_tm_error {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.tokyo_tm_error_inner h1 {
  font-size: 120px;
  font-weight: 700;
  line-height: 1.2;
  @media screen and (max-width: 767px) {
    font-size: 60px;
  }
}
.tokyo_tm_error_inner h3 {
  font-size: 35px;
  line-height: 1.3;
  margin-bottom: 15px;
  @media screen and (max-width: 767px) {
    font-size: 26px;
  }
}
.tokyo_tm_error_inner p {
  font-size: 18px;
  margin-bottom: 25px;
}

.not-found-wrapper {
  .copyright {
    padding-top: 30px;
  }
}

.footer-wrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
  background: #fff;
  display: none;

  @media (max-width: 450px) {
    display: block;
  }

  .copyright {
    padding: 15px;
    a {
      padding-left: 5px;
      color: #787878;
      font-weight: 500;
      transition: all 0.3s ease;
      text-decoration: none;
      &:hover {
        color: #000;
        letter-spacing: 2px;
      }
    }
  }
}

@media (max-width: 576px) {
  .tokyo_tm_contact {
    padding-bottom: 100px;
  }
  .tokyo_tm_news {
    padding-bottom: 60px;
  }
  .tokyo_tm_portfolio {
    padding-bottom: 70px;
  }
  .tokyo_tm_about {
    padding-bottom: 95px;
  }
  .tokyo_tm_home {
    padding-bottom: 90px;
    min-height: auto;
  }
}

.popup-content {
  width: auto;
  padding: 0;
  border: none;
  background: #000;
}
.modal {
  width: 900px;
  height: 600px;
  iframe,
  .frame {
    width: 100%;
    height: 100%;
  }
  .close_modal {
    position: absolute;
    cursor: pointer;
    right: -12px;
    top: -25px;
    background: #fff;
    border: none;
    outline: none;
    padding: 5px 10px;
    font-size: 20px;
    border-radius: 50%;
  }
}
.tab {
  margin-left: 2rem;
}
.tabtab {
  margin-left: 2rem;
}
.tab a {
  color: inherit;
  text-decoration: none;
}
.tab a:hover {
  filter: opacity(0.7);
}
.tokyo_tm_about .description .category {
  margin-bottom: 1rem;
}
